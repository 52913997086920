import React, { useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Check from '@material-ui/icons/Check';
import Save from '@material-ui/icons/Save';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
});


const LoadingButton = React.forwardRef((props, ref) => {
    const { classes, ...other } = props;

    const [state, setState] = useState({
        done: false,
        loading: false,
        disabled: true
    });
    const updateState = newState => setState(Object.assign({}, state, newState));

    useImperativeHandle(ref, () => ({
        setLoading() {
            updateState({
                loading: true,
                done: false
            })
        },
        setDone() {
            updateState({
                loading: false,
                done: true
            })
            setTimeout(() => {
                updateState({
                    loading: false,
                    done: false,
                    disabled: true
                })
            }, 1000);
        },
        reset() {
            updateState({
                loading: false,
                done: false,
                disabled: true
            })
        },
        enable(){
            if(state.disabled){
                updateState({
                    disabled: false
                })
            }
        }
    }))


    if (state.done) {
        return (
            <Button color="primary" variant="contained" className={classes.button} {...other}
                onClick={() => { }}
                startIcon={<Check />} > Kaydedildi </Button>
        );
    }
    else if (state.loading) {
        return (
            <Button color="primary" variant="contained" className={classes.button} {...other}
                onClick={() => { }}
                startIcon={<CircularProgress size={14} thickness={4} color="white" />} > Kaydediliyor... </Button>
        );
    } else {
        return (
            <Button color="primary" variant="contained" disabled={state.disabled}  startIcon={<Save />} {...other} />
        );
    }
});

LoadingButton.defaultProps = {
};

LoadingButton.propTypes = {
    classes: PropTypes.object.isRequired,
    style: PropTypes.object,
    onClick: PropTypes.func
};

export default withStyles(styles)(LoadingButton);