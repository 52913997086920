/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import makeRequest from '../../modules/request/requests'
import { KTSVG } from '../../../_metronic/helpers'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
    </div>
    {/* end::Row */}

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
  </>
)



const DashboardWrapper: FC = () => {

  const [state, setState] = useState({
    unreadApplications: 0, unreadMessages: 0
  });
  const updateState = newState => setState(Object.assign({}, state, newState));

  useEffect(() => {
    makeRequest({
      url: '/auth/auth-control',
      method: 'GET'
    })

    makeRequest({
      method: makeRequest.METHOD_TYPE.GET,
      url: '/applications/unread/'
    }).then(function (response) {
      if (response.data > 0) {
        updateState({ unreadApplications: response.data });
      }
    })
      .catch(function (error) {
        console.log(error);
      });


    makeRequest({
      method: makeRequest.METHOD_TYPE.GET,
      url: '/contacts/unread/'
    }).then(function (response) {
      if (response.data > 0) {
        updateState({ unreadMessages: response.data });
      }
    })
      .catch(function (error) {
        console.log(error);
      });

  }, []);


  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <div className='card-xl-stretch mb-xl-8' >

            <div className='card card-xl-stretch mb-5 mb-xl-8'>
              <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Bildirimler</h3>

              </div>
              <div className='card-body pt-0'>


                {(!state.unreadApplications && !state.unreadMessages) ? (
                  <span>Yeni bildirim yok</span>

                ) : ''}

                {(state.unreadApplications && state.unreadApplications > 0) ?

                  <div className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'>
                    <span className='svg-icon svg-icon-warning me-5'>
                      <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                    </span>
                    <div className='flex-grow-1 me-2'>
                      <a href='/applications' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                        {state.unreadApplications} Yeni başvuru
                      </a>

                    </div>
                  </div>
                  : ''
                }

                {(state.unreadMessages && state.unreadMessages > 0) ?

                  <div className='d-flex align-items-center bg-light-warning rounded p-5 mb-7'>
                    <span className='svg-icon svg-icon-warning me-5'>
                      <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                    </span>
                    <div className='flex-grow-1 me-2'>
                      <a href='/contacts' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                        {state.unreadMessages} Yeni mesaj
                      </a>

                    </div>
                  </div>
                  : ''
                }


              </div>
            </div>

          </div>
        </div>
      </div>

      {process.env.NODE_ENV === "development" && <DashboardPage />}

    </>
  )
}

export { DashboardWrapper }
