import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

type Props = {
  basename: string
}

// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: '#009ef7',
      contrastText: '#ffffff',
    }
  },
  typography: {
    button: {
      textTransform: 'none',
      fontFamily: `"Poppins", "Helvetica", "sans-serif"`,
      fontSize: 12,
    }
  },
  shape: {
    borderRadius: 6,
  }
});

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
        <ThemeProvider theme={theme}>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
          </ThemeProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
