import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'

import { PageLink, PageTitle } from '../../_metronic/layout/core'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const Slides = lazy(() => import('../pages/Slide/Slides.jsx'))
  const SlideText = lazy(() => import('../pages/Slide/SlideText.jsx'))
  const Products = lazy(() => import('../pages/Products/Products.jsx'))
  const About = lazy(() => import('../pages/About.jsx'))
  const Announces = lazy(() => import('../pages/Announces/Announces.jsx'))
  const AnnounceDetail = lazy(() => import('../pages/Announces/AnnounceDetail.jsx'))
  const Certificates = lazy(() => import('../pages/Certificate/Certificates.jsx'))
  const CertificateCategories = lazy(() => import('../pages/Certificate/CertificateCategories.jsx'))
  const Documents = lazy(() => import('../pages/Documents.jsx'))
  const ECatalogs = lazy(() => import('../pages/Catalogs/ECatalogs.jsx'))
  const AddCatalog = lazy(() => import('../pages/Catalogs/AddCatalog.jsx'))
  const Gallery = lazy(() => import('../pages/Gallery.jsx'))
  const KvkkDocuments = lazy(() => import('../pages/KvkkDocuments.jsx'))
  const ProductCategories = lazy(() => import('../pages/ProductCategory/ProductCategories.jsx'))
  const ProductCategoryDetail = lazy(() => import('../pages/ProductCategory/ProductCategoryDetail.jsx'))
  const ProductDetail = lazy(() => import('../pages/Products/ProductDetail.jsx'))
  const ReferenceCategories = lazy(() => import('../pages/References/ReferenceCategories.jsx'))
  const References = lazy(() => import('../pages/References/References.jsx'))
  const ReferenceDetail = lazy(() => import('../pages/References/ReferenceDetail.jsx'))
  const Stores = lazy(() => import('../pages/Stores/Stores.jsx'))
  const StoreDetail = lazy(() => import('../pages/Stores/StoreDetail.jsx'))
  const Settings = lazy(() => import('../pages/Settings.jsx'))
  const Dnd = lazy(() => import('../pages/Dnd.jsx'))
  const Applications = lazy(() => import('../pages/Applications/Applications.jsx'))
  const Contacts = lazy(() => import('../pages/Contacts/Contacts.jsx'))
  const Staffs = lazy(() => import('../pages/Staffs/Staffs.jsx'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>

        <Route path='/about' component={About} />
        <Route path='/announces' component={Announces} />
        <Route path='/announce-detail/:id' component={AnnounceDetail} />
        <Route path='/announce-detail' component={AnnounceDetail} />
        <Route path='/certificates' component={Certificates} />
        <Route path='/certificate-categories' component={CertificateCategories} />
        <Route path='/documents' component={Documents} />
        <Route path='/e-catalogs' component={ECatalogs} />
        <Route path='/e-catalogs/new' component={AddCatalog} />
        <Route path='/gallery' component={Gallery} />
        <Route path='/kvkk-documents' component={KvkkDocuments} />
        <Route path='/product-categories' component={ProductCategories} />
        <Route path='/product-category-detail/:id' component={ProductCategoryDetail} />
        <Route path='/products' component={Products} />
        <Route path='/product-detail/:id' component={ProductDetail} />
        <Route path='/product-detail' component={ProductDetail} />
        <Route path='/project-categories' component={ReferenceCategories} />
        <Route path='/projects' component={References} />
        <Route path='/project-detail/:id' component={ReferenceDetail} />
        <Route path='/project-detail' component={ReferenceDetail} />
        <Route path='/slides' component={Slides} />
        <Route path='/slide-text/:id' component={SlideText} />
        <Route path='/stores' component={Stores} />
        <Route path='/store-detail/:id' component={StoreDetail} />
        <Route path='/store-detail' component={StoreDetail} />
        <Route path='/settings' component={Settings} />
        <Route path='/dnd' component={Dnd} />
        <Route path='/applications' component={Applications} />
        <Route path='/contacts' component={Contacts} />
        <Route path='/staffs' component={Staffs} />

        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
