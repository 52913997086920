
import { Logout } from '../auth'
import store from '../../../setup/redux/Store'
import { actions } from '../auth/redux/AuthRedux'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL;

const makeRequest = async (
    {
        data,
        url,
        method,
        contentType = makeRequest.CONTENT_TYPE.JSON
    }
) => {

    const headers = {
        "Content-Type": contentType
    }

    try {
        switch (method) {
            case makeRequest.METHOD_TYPE.POST: {
                const result = await axios.post(API_URL + url, data, { headers })
                return result
            }
            case makeRequest.METHOD_TYPE.GET: {
                const result = await axios.get(API_URL + url)
                return result
            }
            default:
                return null;
        }
    }
    catch (e) {
        if (e.response) {
            const { status } = e.response;
            if (status === 401) {
                alert('Oturumunuz sonlanmıştır, lütfen tekrar giriş yapınız.');
                 store.dispatch(actions.logout())
            }
            else {
                throw e;
            }
        }
    }
}

makeRequest.METHOD_TYPE = {
    POST: "POST",
    GET: "GET"
}

makeRequest.CONTENT_TYPE = {
    JSON: "application/json",
    MULTIPART: "multipart/form-data"
}

export default makeRequest